<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{ name: page_route }"
          />
          <span
            class="text-h6 ml-1"
            v-text="`${$route.meta.title} | IDP ${id}`"
          />
        </v-col>
        <v-col v-if="data.id" cols="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip
            v-if="!data.active && login.permissions.providers.update"
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                :color="data.end_agreement ? '' : 'warning'"
                @click.prevent="endEgreementDlg"
              >
                <v-icon> mdi-calendar-export </v-icon>
              </v-btn>
            </template>
            <span v-text="'Termino de Convenio'" />
          </v-tooltip>
          <BtnCircle
            v-if="data.active && login.permissions.providers.update"
            tLabel="Editar"
            tPosition="bottom"
            icon="mdi-pencil"
            color="info"
            size="x-small"
            :to="{ name: `${page_route}.update`, params: { id: id } }"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <span class="text-caption" v-text="'GENERAL'" />
              <v-icon small :color="data.active ? 'success' : 'error'">
                mdi-circle
              </v-icon>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="3">
                  <ViewData label="Nombre" :value="data.name" />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Nombre de comercial"
                    :value="data.trade_name"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Tipo de proveedor"
                    :value="data.provider_type.provider_type"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Concentrador"
                    :value="
                      data.provider_brand_id
                        ? data.provider_brand.provider_brand
                        : 'NINGUNO'
                    "
                    :alert="!data.provider_brand_id ? true : false"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  v-if="
                    data.provider_type_id === 4 && data.status_account_format
                  "
                >
                  <ViewData
                    label="Formato de estado de cuenta"
                    :value="data.status_account_format.status_account_format"
                  />
                </v-col>
                <v-col cols="12">
                  <ViewData
                    label="Observación"
                    :value="data.observation"
                    :alert="data.observation ? true : false"
                  />
                </v-col>
                <v-col v-if="data.end_agreement" cols="12">
                  <v-divider />
                </v-col>
                <v-col v-if="data.end_agreement" cols="12" md="3">
                  <ViewData
                    label="Termino convenio"
                    :value="data.end_agreement"
                  />
                </v-col>
                <v-col v-if="data.end_agreement" cols="12" md="9">
                  <ViewData
                    label="Observación"
                    :value="data.end_agreement_observation"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'BANCO'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="4">
                  <ViewData
                    label="Nombre"
                    :value="data.bank_id ? data.bank.bank : 'PENDIENTE'"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <ViewData
                    label="Número de cuenta"
                    :value="data.account_number"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <ViewData label="CLABE" :value="data.clabe" />
                </v-col>
                <v-col cols="12" md="4">
                  <ViewData
                    label="Descuento"
                    :value="data.discount ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <ViewData
                    label="Cuenta bancaria (PDF)"
                    :value="data.bank_account_pdf ? null : '-'"
                  />
                  <v-btn
                    v-if="data.bank_account_pdf"
                    x-small
                    fab
                    outlined
                    class="mr-1"
                    color="warning"
                    :href="
                      url_documents + '/providers/' + data.bank_account_pdf
                    "
                    target="_blank"
                  >
                    <v-icon v-text="'mdi-download'" />
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <ViewData
                    label="Valid. cuenta (PDF)"
                    :value="data.account_validation_pdf ? null : '-'"
                  />
                  <v-btn
                    v-if="data.account_validation_pdf"
                    x-small
                    fab
                    outlined
                    class="mr-1"
                    color="warning"
                    :href="
                      url_documents +
                      '/providers/' +
                      data.account_validation_pdf
                    "
                    target="_blank"
                  >
                    <v-icon v-text="'mdi-download'" />
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'DOMICILIO'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="3">
                  <ViewData label="Calle" :value="data.street" />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Número exterior"
                    :value="data.external_number"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Número interior"
                    :value="data.internal_number"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData label="Colonia" :value="data.suburb" />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData label="Estado" :value="data.entity" />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData label="Municipio" :value="data.municipality" />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData label="Código postal" :value="data.zip" />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Comp. domicilio (PDF)"
                    :value="data.proof_address_pdf ? null : '-'"
                  />
                  <v-btn
                    v-if="data.proof_address_pdf"
                    x-small
                    fab
                    outlined
                    color="warning"
                    :href="
                      url_documents + '/providers/' + data.proof_address_pdf
                    "
                    target="_blank"
                  >
                    <v-icon v-text="'mdi-download'" />
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'LEGAL'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="3">
                  <ViewData label="Razón social" :value="data.legal_name" />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData label="RFC" :value="data.code_tax" />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Domicilio fiscal"
                    :value="data.legal_address"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="RFC (PDF)"
                    :value="data.code_tax_pdf ? null : '-'"
                  />
                  <v-btn
                    v-if="data.code_tax_pdf"
                    x-small
                    fab
                    outlined
                    class="mr-1"
                    color="warning"
                    :href="url_documents + '/providers/' + data.code_tax_pdf"
                    target="_blank"
                  >
                    <v-icon v-text="'mdi-download'" />
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'CONTACTO'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="8">
                  <ViewData
                    label="Nombre de contacto"
                    :value="data.contact_name"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <ViewData label="Teléfono" :value="data.phone" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'OTROS'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="3">
                  <ViewData label="IVA" :value="data.iva" />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData label="Día de pago" :value="data.pay_day" />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData label="Factor CPT" :value="data.cpt_factor" />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="NET ID"
                    :value="data.net_id ? data.net_id : ''"
                    :alert="!data.net_id ? true : false"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Descuento en factura"
                    :value="data.bill_discount ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Tipo de descuento"
                    :value="discounts[data.fixed_discount].discount"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Porcentaje de descuento"
                    :value="data.fund_without_discount"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Formato DDC en factura"
                    :value="data.ddc_format ? data.ddc_format : 'NINGUNO'"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Nota de crédito posterior"
                    :value="data.credit_note_after ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Descuento, deducible y coaseguro"
                    :value="data.ddc ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Monto exento"
                    :value="data.exempt_amount ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="IVA, deducible y coaseguro"
                    :value="data.idc ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Convenio"
                    :value="data.agreement ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Segunda firma red"
                    :value="data.revision ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="C.S."
                    :value="data.cs"
                    :alert="!data.cs ? true : false"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Sumar deducible y coseguro"
                    :value="data.add_deductible_coinsurance ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="No verificar monto nota de crédito"
                    :value="data.notes_verify_amount ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Aumento de suma automático"
                    :value="data.automatic_authorization ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Porc. cobro particulares"
                    :value="data.particular_payment"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Envio correos cobro particulares"
                    :value="data.service_particular_email ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Generar carta servicio automáticamente"
                    :value="data.auto_letter_generated ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Módulo SM"
                    :value="data.with_module ? 'SI' : 'NO'"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2
                class="text-caption"
                v-text="'CONDICIONES URGENCIAS SENTIDAS'"
              />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="3">
                  <ViewData
                    label="Activo"
                    :value="data.express_letter ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col v-if="data.express_letter" cols="12" md="3">
                  <ViewData
                    label="Monto"
                    :value="numberFormat(data.express_amount)"
                  />
                </v-col>
                <v-col v-if="data.express_letter" cols="12">
                  <ViewData label="Observación" :value="data.express_caption" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'FACTURAS'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  v-for="(item, i) in data.invoices_receptions"
                  :key="i"
                >
                  <v-row v-if="item.active" dense>
                    <v-col cols="12" md="5">
                      <ViewData
                        :label="`Día de recepción (${i + 1})`"
                        :value="item.day.day"
                      />
                    </v-col>
                    <v-col cols="12" md="3">
                      <ViewData label="Hora de inicio" :value="item.start" />
                    </v-col>
                    <v-col cols="12" md="4">
                      <ViewData label="Hora de término" :value="item.end" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2
                class="text-caption"
                v-text="'IENVIO DE CORREOS ELECTRÓNICOS'"
              />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  v-for="(provider_email, i) in data.provider_emails"
                  :key="i"
                >
                  <v-row v-if="provider_email.active" dense>
                    <v-col cols="5">
                      <ViewData
                        :label="`Correo electrónico(${i + 1})`"
                        :value="provider_email.email"
                      />
                    </v-col>
                    <v-col cols="5">
                      <ViewData
                        label="Uso"
                        :value="
                          provider_email.for_service
                            ? 'Servicio particular'
                            : ' Carta ingresos'
                        "
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="mx-auto">
            <v-card-title>
              <h2 class="text-caption" v-text="'REGISTRO'" />
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" md="6">
                  <ViewData
                    label="Creación"
                    :value="data.created_by.email"
                    :subvalue="data.created_at"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <ViewData
                    label="U. actualización"
                    :value="data.updated_by.email"
                    :subvalue="data.updated_at"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="login.permissions.providers.delete" cols="12">
          <BtnCircle
            v-if="data.active"
            tLabel="Desactivar"
            tPosition="top"
            icon="mdi-delete"
            color="error"
            size="x-small"
            :click="HandleDesactivate"
            :loading="loading"
          />
          <v-tooltip v-else bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                color="warning"
                @click.prevent="restore"
              >
                <v-icon> mdi-delete-restore </v-icon>
              </v-btn>
            </template>
            <span v-text="'Activar'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
    <v-dialog
      v-model="end_agreement_dlg"
      scrollable
      persistent
      max-width="450px"
    >
      <v-card tile :disabled="end_agreement_ldg" :loading="end_agreement_ldg">
        <v-toolbar dark dense>
          <v-toolbar-title> REGISTRAR </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="end_agreement_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="end_agreement_data">
          <v-form v-on:submit.prevent ref="end_agreement_form" lazy-validation>
            <v-row dense class="pt-5">
              <v-col cols="12">
                <DatePicker
                  label="Termino convenio"
                  :model.sync="end_agreement_data.end_agreement"
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="end_agreement_data.end_agreement_observation"
                  label="Observación"
                  dense
                  rows="2"
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  dark
                  small
                  color="warning"
                  @click.prevent="endEgreementHandle"
                >
                  Continuar
                  <v-icon small right> mdi-calendar-export </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  URL_DOCUMENTS,
  dateTimeNow,
  rules,
} from "../../control";
import { show, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";
import DatePicker from "../../components/DatePicker.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    FaqDlg,
    DatePicker,
  },
  data() {
    return {
      page_route: "providers",
      id: this.$attrs.id,
      login: this.$store.getters.getLogin,
      loading: true,
      rules: rules(),
      data: {
        provider_type: [],
        created_by: {},
        updated_by: {},
        status_account_format: {},
      },
      url_documents: URL_DOCUMENTS,
      discounts: [
        { id: 0, discount: "Variable" },
        { id: 1, discount: "Fijo" },
      ],
      end_agreement_data: null,
      end_agreement_dlg: false,
      end_agreement_ldg: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getItem() {
      show(this.page_route, this.login.token, this.id).then((rsp) => {
        this.data = rsp;
        this.loading = false;
      });
    },
    HandleDesactivate() {
      this.$swal
        .fire(msgConfirm("Confirma la desactivación del registro?"))
        .then((rsp) => {
          if (rsp.isConfirmed) {
            this.loading = true;
            //destroy action
            destroy(
              this.page_route,
              this.login.token,
              this.id,
              this.login.id
            ).then((rsp) => {
              this.$swal.fire(
                msgAlert(rsp.success ? "success" : "error", rsp.message)
              );

              rsp.success
                ? this.$router.push({ name: this.page_route })
                : console.log(rsp.message);
            });
          }
        });
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    endEgreementDlg() {
      this.end_agreement_data = {
        id: this.data.id,
        end_agreement: dateTimeNow().substring(0, 10),
        end_agreement_observation: "",
      };
      this.end_agreement_ldg = false;
      this.end_agreement_dlg = true;
    },
    endEgreementHandle() {
      if (this.$refs.end_agreement_form.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma registrar información?"))
          .then((rsp) => {
            if (rsp.isConfirmed) {
              this.end_agreement_ldg = false;

              Axios.post(
                URL_API + "/providers/end_agreement",
                this.end_agreement_data,
                headersToken(this.login.token)
              ).then((rsp) => {
                this.$swal.fire(
                  msgAlert(
                    rsp.data.success ? "success" : "error",
                    rsp.data.message
                  )
                );

                if (rsp.data.success) {
                  this.end_agreement_dlg = false;
                  this.getItem();
                }

                this.end_agreement_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    restore() {
      this.$swal
        .fire(msgConfirm("¿Confirma activar el registro?"))
        .then((rsp) => {
          if (rsp.isConfirmed) {
            this.loading = false;

            Axios.post(
              URL_API + "/providers/restore",
              { id: this.data.id },
              headersToken(this.login.token)
            ).then((rsp) => {
              this.$swal.fire(
                msgAlert(
                  rsp.data.success ? "success" : "error",
                  rsp.data.message
                )
              );

              if (rsp.data.success) {
                this.getItem();
              }

              this.loading = false;
            });
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.getItem();
  },
};
</script>